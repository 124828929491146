<template>
  <div id="scan-form-page">
    <v-row class="ma-0">
      <DetailSection :color="color" :agentId="agentId" :textColor="textColor" :data="model" :tab="tabItems[tab]" :damageLists="archLists" :selectOpt="selectOpt" @pdfSelection="model && model.report_pdfs && pdfSelected($event.value,$event.option)" :selectedFile="model.pdf || []"/>
      <v-col cols="12" class="col-md-12 col-lg-8 quote-skeleton" v-if="scanLoading">
        <v-skeleton-loader :loading="true" type="image" class="mb-5"></v-skeleton-loader>
        <v-skeleton-loader :loading="true" type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" class="col-md-12 col-lg-8 form-scan-section" v-if="!scanLoading">
        <v-tabs v-if="model && model.meta" elevation="2" :background-color="color" :dark=!agentId :style="`color: ${textColor}`" v-model="tab" @change="tabChange">
          <v-tab v-for="item in tabItems" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-toolbar :color="color" :style="`color: ${textColor}`" class="scan-title-bar" elevation="3" v-else>
          <v-toolbar-title class="text-section-title">Scans</v-toolbar-title>
        </v-toolbar>
        <v-card v-if="!model || !model.meta || (tabItems[tab] == 'tire' && !model.data)" class="pa-6" style="height: 300px;">
          <v-row class="ma-0 text-capitalize h-full" align="center" justify="center">
            Scan Not detected.!
          </v-row>
        </v-card>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabItems" :key="item">
            <v-row class="ma-0 scan-sections">
              <CarArchitect :color="color" :textColor="textColor" v-if="(item === 'arch' || item === 'chasis') && model && model.meta" :clientId="model.client_id || null" :model="{ tab: item, metaModel: model && model.meta ? model.meta : null}"
                            @generateData="archLists = $event" @saved="updateAnnotatedImage($event, 'Chassis Scan')"
                            @editedAnnotate="updateAnnotatedImage($event.value, $event.option)" :damages="model?.damages ? model.damages: []"/>
              <TireScan :color="color" :textColor="textColor" v-if="item === 'tire'" :model="model?.data?.length ? model.data : null" :items="model.selectedItems" @selected-repairs-updated="updateSelectedRepairs"/>
              <RimsScan :color="color" :textColor="textColor" v-if="item === 'rims'" :model="model?.data?.length ? model.data : null"/>
              <WatchVideo :color="color" :textColor="textColor" v-if="item === 'arch' && model?.videos" :videoList="model?.videos ? model.videos : []" :imgVideoLists="model && model.imgVideoLists ? model.imgVideoLists : []"
                          @saved="updateAnnotatedImage($event, 'Captured')"/>
              <DetectionImg :color="color" :textColor="textColor" :selectOpt="selectOpt" v-if="item === 'arch' && model && model.damages" @editedAnnotate="updateAnnotatedImage($event.value, $event.option)" :selectedFile="model.damages || []"
                            @mailSelectImg="selectedAttachment.damages = $event"/>
              <TireScanGraph v-if="item === 'tire' && !agentId" :selectOpt="selectOpt" :model="model?.data?.length ? model.data : null"
                             @editedAnnotate="updateAnnotatedImage($event.value, $event.option)"/>
              <SavedImages v-if="!agentId" :color="color" :textColor="textColor" :selectOpt="selectOpt" :lists="model?.annotates ? model.annotates : []" @delete="deleteSavedImg($event)" @saved="updateAnnotatedImage($event, 'Annotated')"
                           @mailSelectImg="selectedAttachment.annotates = $event"/>
              <CommTab :hidden="agentId" :color="color" :textColor="textColor" :selectedImages="selectedAttachment" @selectEnable="selectOpt = $event"/>
              <ProductTable :color="color" :textColor="textColor" v-if="scanId && agentId" @productUpdate="setProduct" :products="model && model.products ? model.products : []" title="Add Products"/>
              <addParts :color="color" :textColor="textColor" v-if="scanId && agentId" @partsUpdate="model.parts = $event" :parts="partsList" :lists="model && model.parts ? model.parts: []"/>
              <addComment :color="color" :textColor="textColor" v-if="scanId && agentId" :data="commentModel" @modelUpdate="updateCommentModel"/>
              <v-row v-if="agentId" class="ma-0 my-3" justify="center" align="center">
                <v-btn type="submit" color="primary" @click="saveGenerateQuote" :disabled="loading">
                  {{ loading ? 'Loading..' : 'Save and Generate Quote' }}
                  <v-icon right v-if="!loading">mdi-download</v-icon>
                  <v-progress-circular indeterminate color="primary" v-if="loading"
                      :size="20" class="ml-2"></v-progress-circular>
                </v-btn>
              </v-row>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  props: {
    refresh: Boolean,
    color: {
      type: String,
      default: 'primary'
    },
    textColor: {
      type: String,
      default: '#ffffff'
    }
  },
  emits: ['refreshed'],
  components: {
    DetailSection: () => import("./components/DetailSection"),
    CarArchitect: () => import("./components/CarArchitecture"),
    TireScan: () => import("./components/tire-scan/TireData"),
    RimsScan: () => import("./components/RimsScan"),
    TireScanGraph: () => import("./components/tire-scan/ScanImgGraph"),
    WatchVideo: () => import("./components/WatchVideo.vue"),
    DetectionImg: () => import("./components/DetectionImg"),
    SavedImages: () => import("./components/SavedImages"),
    CommTab: () => import("./components/CommunicationTab"),
    ProductTable: () => import("../setting/clients/components/productTable"),
    addParts: () => import("../quote/components/AddPart"),
    addComment: () => import("../quote/components/AddComment"),
  },
  data: (vm) => ({
    scanId: (vm.$route.params.scanId).toLowerCase(),
    agentId: vm.$route.params.agentId || null,
    model: {},
    tempModel: {
      basic: {},
      arch: {},
    },
    archLists: {},
    tabItems: [],
    tab: 0,
    selectOpt: false,
    selectedAttachment: {
      annotates: [],
      damages: [],
      pdf: []
    },
    commentModel: "",
    selectedParts: [],
    items: [],
    partsList: [],
    loading: false,
    scanDetails:[]
  }),
  computed: {
    ...mapGetters(['archMetaDetails', 'scanLoading'])
  },
  watch: {
    refresh(newValue) {
      if (newValue && this.scanId) this.getScanDetails();
    },
    $route(newVal) {
      this.agentId = newVal.params.agentId || null;
      this.scanId = (newVal.params.scanId).toLowerCase();
    }
  },
  created(vm) {
    if (this.scanId) {
      this.getScanDetails(vm);
      this.getPanelGroupPositions();
      // this.wsScanData();
    }
  },
  methods: {
    ...mapActions(['getSelectedScan', 'updateSavedImage', 'deleteAnnotateImg',
      'searchMetaArchData', 'getPanelGroupPositions', 'getClient', 'createQuote', 'getPartLists', 'searchQuote', 'setProductsList']),

    async getScanDetails(vm) {
      const scan = await this.getSelectedScan(this.scanId);
      this.tabItems = [];
      if (scan.arch) this.tabItems.push('arch');
      if (scan.chasis) this.tabItems.push('chasis');
      if (scan.tire) this.tabItems.push('tire');
      // Client requested) Hide rims tab and saved image sections
      // if (this.agentId) this.tabItems.push('rims');
      this.modelBind(scan);

      this.scanDetails = {
        'licensePlate': scan.licensePlate,
        'agent_id': scan.agent_id,
        'type': scan.type,
        'location_state':scan.location_state
        }

      // Call getClient method with the fetched clientId
      if(scan.client_id) {
          const clientData = await this.getClient({ id: scan.client_id, params: { type: scan.type } });
          this.$store.commit("setProductsList", clientData.products)
          // Check if clientData has parts before mapping over it
          if (clientData.parts) {
              this.partsList = clientData.parts.map((v) => {
                  return { "text": v, "value": v }
              });
          }
      }

      // Check if scanId exists and call searchQuote API to prefill the form
      const quote = await this.searchQuote(this.scanId);
      const report_pdfs = [];

      if(scan.pdf_url){
        report_pdfs.push({url: scan.pdf_url, path: scan.pdf_path, label: 'View Scan Report'});
      }

      if(quote.proforma){
        report_pdfs.push({url: quote.proforma, path: quote.proforma_path, label: 'Customer Portal PDF'});
      }

      if(quote.pdf){
        report_pdfs.push({url: quote.pdf, path: quote.pdf_path, label: 'View Quote'});
      }

      // Pass proforma URL to this.model
      this.model = {
        ...this.model,
        report_pdfs: report_pdfs,
      };

      if (!_.isEmpty(quote)) {
        // Update the form fields with the retrieved data
        this.model.products = quote.products || [];
        this.model.parts = quote.parts || [];
        this.model.selectedItems = quote.items || {};
        this.commentModel = {
          manual_check: quote.manual_check,
          assessor_comments: quote.assessor_comments || ''
        };
      }
    },
    setModel(tab) {
      if (this.tabItems[tab] == 'chasis') this.model = this.tempModel.chasis;
      else if (this.tabItems[tab] == 'tire') this.model = this.tempModel.tire;
      else if (this.tabItems[tab] == 'rims') this.model = this.tempModel.rims;
      else this.model = this.tempModel.arch;
      this.updateModelData(this.tabItems[tab])
      this.$emit('refreshed');
    },
    async updateModelData(tab) {
      if (tab == 'arch' && !this.archMetaDetails) this.getCarArchAPI(this.model.meta.id);
      if (this.model && this.model.videos) this.model.imgVideoLists = await Object.keys(this.model.videos).map(key => {
        return this.$helpers.captureImgFromVdo(null, 0.25, this.model.videos[key]);
      });
    },
    tabChange(tab) {
      this.tempModel[this.tabItems[tab]] = { ...this.model, ...this.tempModel[this.tabItems[tab]] };
      this.setModel(tab);
    },
    getCarArchAPI(id) {
      this.searchMetaArchData({ arch_id: id, response: 'countbymeasure' });
    },
    updateAnnotatedImage(imgURL, name) {
      const reqData = new FormData(),
        headers = { 'Content-Type': 'multipart/form-data' };
      reqData.append('file', imgURL);
      this.updateSavedImage({ id: this.model.meta.scan_id, file: reqData, edit: name }, headers).then(res => {
        this.model.annotates.push(res);
      });
    },
    deleteSavedImg(imgId) {
      this.deleteAnnotateImg(imgId).then(res => {
        const images = this.model.annotates.length ? this.model.annotates.filter(item => item.id !== imgId) : [];
        this.model.annotates = images.length ? images : [];
      });
    },
    pdfSelected(selectedPdf) {
        this.selectedAttachment.pdf = [];
        if (selectedPdf) {
          this.selectedAttachment.pdf.push(...selectedPdf);
        }
    },
    modelBind(response) {
      const basicModel = Object.fromEntries(Object.entries(response).filter(([key]) => !key.includes('arch') && !key.includes('chasis') && !key.includes('tire')));
      this.tempModel.arch = response.arch ? { ...basicModel, ...response.arch } : null;
      this.tempModel.chasis = response.chasis ? { ...basicModel, ...response.chasis } : null;
      this.tempModel.tire = response.tire ? { ...basicModel, ...response.tire } : null;
      this.tempModel.rims = response.rims ? { ...basicModel, ...response.tire } : basicModel;
      this.setModel(this.tab);
    },
    wsScanData() {
      Echo.channel('scan').listen('.updated', (scan) => scan.status == 'uploaded' && this.modelBind(scan));
    },
    updateCommentModel(commentModel) {
      this.commentModel = commentModel; // Update the comment model
    },
    updateSelectedRepairs(items) {
      // Map through each item in the items array and add the type property
      const updatedItems = items.map(item => {
        const { index, position, ...rest } = item;
        return {
          ...rest,
          type: this.model.type
        };
      });
      // Update the model.items array with the updated items
      this.model.items = updatedItems;
    },
    async saveGenerateQuote() {
      this.loading = true;
      const self = this;
      // Extract the required properties from this.model
      const { items, products, parts, client_id } = this.model;
      // Clone items to prevent mutation and transform if it's an array
      let clonedItems = {};
      if (Array.isArray(items) && items.length > 0) {
        clonedItems = Object.assign({}, ...items.map((item) => ({ [item.panel_id]: item })))
      }
      // Create the reqData object with the required properties
      const reqData = {
        client_id,
        scan_id: this.scanId,
        products,
        parts,
        items: clonedItems,
        assessor_comments: this.commentModel.assessor_comments,
        scan_details: this.scanDetails
      };
      // Call createQuote with the extracted properties
      this.createQuote({ ...reqData }).then(res => {
        self.loading = false;

        if(res.proforma) {
          window.open(res.proforma,'_blank');
        }
      }).catch(err => this.loading = false);
    },
    setProduct(ev) {
      if (!this.model.products) {
        this.model.products = [];
      }
      const prodIndex = this.model.products.length ? this.model.products.findIndex(item => item.value == ev.value) : -1;
      if (prodIndex !== -1) {
        this.model.products.splice(prodIndex, 1, ev);
      } else {
        this.model.products.push(ev);
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('setArchMetaDetails', null);
    Echo.leaveChannel('scan');
  }
}
</script>

<style lang="scss">
.form-scan-section {
  .v-tabs-items {
    background: transparent !important;
  }
}
</style>
